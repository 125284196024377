// page loader
$pageloader-opacity: 0.95;

// Custom colors
$khPrimary: #42859E;
$accent: #b8be14;

$accent-invert: #fff;
$secondary: #485153;
$secondary-invert: #fff;
$site-background: #F3F2F1;

$app-navbar-height: 53px;
$sidebar-padding: 24px;
$box-padding: 24px;
$col-padding: 49px;
$app-footer-height: 51px;
