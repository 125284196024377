@import "assets/themes/vmt.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "variables.scss";


// Update Bulma's global variables
$primary: $khPrimary;
$family-sans-serif: "Roboto", sans-serif;
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;
$navbar-dropdown-item-hover-color: #fff;
$navbar-dropdown-item-hover-background-color: $accent;

//Add new color variables to the color map.
@import "node_modules/bulma/sass/utilities/derived-variables.sass";

$addColors: (
    "secondary": ($secondary, $secondary-invert),
    "accent": ($accent, $accent-invert)
);
$colors: map-merge($colors, $addColors);

@import "node_modules/bulma-checkradio/src/sass/index";
@import "node_modules/bulma-switch/src/sass/index";
@import "node_modules/bulma/sass/utilities/_all";
@import "node_modules/bulma/sass/base/_all";
@import "node_modules/bulma/sass/elements/button";
@import "node_modules/bulma/sass/elements/container";
@import "node_modules/bulma/sass/elements/title";
@import "node_modules/bulma/sass/elements/table";
@import "node_modules/bulma/sass/elements/tag";
@import "node_modules/bulma/sass/form/_all";
@import "node_modules/bulma/sass/components/navbar";
@import "node_modules/bulma/sass/components/tabs";
@import "node_modules/bulma/sass/components/menu";
@import "node_modules/bulma/sass/layout/section";
@import "node_modules/bulma/sass/helpers/color";
@import "node_modules/bulma-pageloader/src/sass/index";

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    //font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-dialog-container {
    min-width: 400px !important;
    text-align: center;
}
mat-form-field {
    min-width: 300px !important;
}

.map-view {
    min-height: 110px;
    height: 100%;
}

.aside-left {
    flex: 1;
    flex-basis: 20%;
    background: $site-background;
    transition: all 0.3s ease-in;
    overflow-y: auto;
}

.aside-left-project {
    flex: 1;
    flex-basis: 40%;
}

.aside-left-results {
    flex: 1;
    flex-basis: 100%;
}

.aside-left-ev-site-details {
    flex: 1;
    flex-basis: 45%;
}

.aside-bottom {
    flex-basis: 50%;
    overflow: auto;
    transition: all 0.3s ease-in;
}

.aside-presumptions {
    background: #f0f0f0;
    padding: 0 10px 5px 10px;
}

.app-fullheight {
    height: calc(100vh - #{$app-navbar-height} - #{$app-footer-height});
}

.fullheight-exclude-footer {
    height: calc(100vh - #{$app-footer-height});
}

.fullheight {
    height: 100%;
}

.app-fullheight-box {
    height: calc(100vh - #{$app-navbar-height} - #{$sidebar-padding} - #{$box-padding} - #{$app-footer-height});
}

.app-fullheight-side-col {
    height: calc(100vh - #{$app-navbar-height} - #{$sidebar-padding} - #{$col-padding} - #{$box-padding} - #{$app-footer-height});
}

.main {
    height: calc(100vh - #{$app-navbar-height} - #{$app-footer-height});
}

.main-content-col {
    flex: 1;
    flex-basis: 80%;
}

.small {
    flex-basis: 60%;
    transition: all 0.3s ease-in;
}

// vertical
.map-container {
    flex: 1;
    background: #a7d6fe;
}

.hide {
    width: 1px;
    height: 1px;
    flex: 0 0 0;
    opacity: 0;
    overflow: hidden;
}

@for $i from 1 to 1000 {
    .mw-#{$i} {
        min-width: #{$i}px;
    }
}

@for $i from 1 to 1000 {
    .pad-t-#{$i} {
        padding-top: #{$i}px;
    }
}

@for $i from 1 to 1000 {
    .m-l-#{$i} {
        margin-left: #{$i}px;
    }
}

@for $i from 1 to 1000 {
    .m-l-#{$i} {
        margin-left: #{$i}px;
    }
}

.table.is-borderless td,
th {
    border: 0;
}

.table {
    &.is-scrollable {
        tbody {
            overflow-y: scroll;
            width: auto;
            position: absolute;
        }
    }
}

.table-container {
    overflow-y: scroll;
}

.radio-list {
    .is-checkradio[type="radio"] + label {
        display: block;
        margin-left: 0;
    }
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
    scrollbar-width: thin;
    scrollbar-color: #757575 $site-background;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
*::-webkit-scrollbar-track {
    background: $site-background;
}
*::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 20px;
    border: 3px solid $site-background;
}

.esri-attribution {
    background-color: rgba(255,255,255,.4);
}

.file-icon {
    margin-right: 0;
}

.mat-expansion-panel-header-auto {
    height: auto!important;
}

.mat-expansion-indicator::after {
    color: $accent;
}

.tdm-input {
    height: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
     -webkit-box-sizing:border-box;
     -moz-box-sizing: border-box;
}

.tdm-input-fake {
    border: none;
    box-shadow: none;
    font-size: inherit;
    background: transparent;
}

.tdm-column {
    overflow: auto;
}

ng-select.tdm-select {
    width: 100%;
}

.has-text-accent {
    color: $accent !important;
}

.esri-widget {
    font-family: "Roboto","Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.ul-disclaimer {
    list-style: disc;
    margin-left: 2.5rem;
    >li::marker{
        color: $accent;
    }
}

.box-p-3 {
    padding: 3px 3px;
}

.ng-select.is-danger .ng-select-container {
    border: 1px solid $danger;
}

.mat-tab-label.button {
    opacity: 1;
    font-size: 0.9rem;

    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.message-body-land-use {
    padding: 0.25em 1.5em;
}

.message-land-use {
    margin-bottom: 0.25rem;
}

.action-override {
    padding:0;
    border-radius: 4px;
    border: 1px solid $accent;
}

.ng-placeholder::before{
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f002";
    margin-right: 0.5em;
}

.no-search .ng-placeholder::before {
    content: none;
}

.footer.is-secondary {
    background-color: $secondary;
    color: white;
}

.footer {
    padding: 0.5em;
}

.message.is-accent .message-body {
    border-color: var(--accent-color);
    // color: #3E3E44;
    color: #888f0b;
    background: var(--accent-lighter-color);
}

.is-checkradio.is-accent[type=radio]:checked + label::before,
.is-checkradio[type=radio]:checked + label:before,
.is-checkradio[type=checkbox]:checked + label::before,
.is-checkradio[type=checkbox]:checked + label:before {
    border: 0.1rem solid $accent;
}

.esri-layer-list__item-actions-menu {
    background-color: white;
}

.esri-layer-list__item-actions-menu-item--active {
    background-color: white;
}

.esri-icon-layer-list::before {
    content: "\e681";
    color: $accent;
    font-weight: 900;
    background-color: inherit;
}

.esri-layer-list__item-actions-menu-item--active .esri-icon-layer-list::before {
    content: "\e682";
    color: $accent;
    font-weight: 900;
    background-color: inherit;
}

// Stops the ng-select from growing after selecting value longer than dropdown
.ng-value-container {
    width: 0;
}

.col-input.columns:not(:last-child) {
    margin-bottom: 5px;
}

.units {
    font-size: 0.83rem;
    margin-top: -8px;
}

.icon-expander {
    cursor: pointer;
    display: inline-block;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.is-collapsed.icon-expander::before {
    font: var(--fa-font-solid);
    content: "\f101";
    vertical-align: top;
}

.is-expanded.icon-expander::before {
    font: var(--fa-font-solid);
    content: "\f100";
    vertical-align: top;
}

.text-excluded {
    color: #abacb2;
}

// Hide cluster size legend
.esri-layer-list__item:nth-child(1) div.esri-legend__layer-table--size-ramp:nth-child(2){
    display: none;
}

.esri-layer-list__item-container {
    padding: 12px 7px 12px 5px;
}

.esri-layer-list__status-indicator {
    margin-inline: 2px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: gray;
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

.pin {
    cursor: grab;
    filter: drop-shadow(2px 2px 1px #232328);
    &:hover {
        transform: scale(1.1);
    }
    &:active {
        cursor: grabbing;
    }
}

.is-size-6_5 {
    font-size: 0.85rem;
}
